<template>
  <main-layout>
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <div class="text-md-center">
          <h1>404</h1>
          <h2 class="my-3 headline ">
            {{ $t('errors.page_not_found') }}
          </h2>
        </div>
      </v-layout>
    </v-container>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/Main.vue'

export default {
  components: {
    MainLayout
  },
  metaInfo () {
    return { title: this.$t('navigation.not_found') }
  }
}
</script>
<style scoped>
  h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
</style>
